<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        inline
        size="small"
        :model="tableFrom"
        ref="searchForm"
        label-width="120px"
      >
        <el-form-item label="活动名称：" prop="name">
          <el-input
            class="selWidth"
            v-model="tableFrom.name"
            placeholder="请输入活动名称："
          ></el-input>
        </el-form-item>

        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            class="selWidth"
            v-model="tableFrom.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column prop="name" label="活动名称" min-width="120" />
        <el-table-column prop="name" label="活动列表图" min-width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.primaryPicture"
                :preview-src-list="[scope.row.primaryPicture]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="活动轮播图" min-width="400">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.banner">
              <el-image
                v-for="(item, index) in scope.row.banner.split(',')"
                :key="index"
                style="width: 36px; height: 36px; margin-left: 8px"
                :src="item"
                :preview-src-list="[item]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="应收金额" min-width="120" />
        <el-table-column prop="name" label="支付方式" min-width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              {{
                scope.row.payType == 1
                  ? "积分"
                  : scope.row.payType == 2
                  ? "现金"
                  : "积分+现金"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="排序" min-width="120" />
        <el-table-column prop="createTime" label="添加时间" min-width="120" />

        <el-table-column prop="sort" label="活动时间时间" min-width="120">
          <template slot-scope="scope">
            <div>
              {{ `${scope.row.startTime}至${scope.row.endTime}` }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态" min-width="150">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              active-text="开启"
              inactive-text="关闭"
              @change="onchangeIsShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="205" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="editGoods(scope.row.id)"
              >管理商品</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="活动名称:" prop="name">
          <el-input
            class="inputWidth"
            placeholder="请输入活动名称"
            maxlength="50"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动列表图:" prop="primaryPictureList">
          <FormImgUpload
            :url="form.primaryPictureList"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:670X304，格式：jpeg,png,jpg</div>
        </el-form-item>

        <el-form-item label="活动轮播图:" prop="bannerList">
          <FormImgUpload
            :url="form.bannerList"
            @upload="uploadBanner"
            :limit="9"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:670X304，格式：jpeg,png,jpg</div>
        </el-form-item>

        <el-form-item label="支付方式:" prop="payType">
          <el-radio-group v-model="form.payType">
            <el-radio :label="1">余额</el-radio>
            <el-radio :label="2">积分</el-radio>
            <el-radio :label="3">余额+积分</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="有效时间:" prop="time">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <!-- <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            format="yyyy/MM/dd"
          >
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="是否显示:" prop="state">
          <el-switch v-model="form.state" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>

        <el-form-item label="排序:" prop="sort">
          <el-input-number
            v-model="form.sort"
            :min="1"
            :max="10"
            label="描述文字"
          ></el-input-number>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :loading="vLoading"
            @click="submitForm('form')"
            >提交</el-button
          >
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  activityList,
  activityAdd,
  activityStateUp,
  activityDel,
  activityUp,
} from "@/api/market/activity";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { getIndex } from "@/utils/other";
export default {
  name: "banner",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      vLoading: false,
      title: "新增活动",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        storeId: "",
      },
      storeList: {},
      form: {
        name: "",
        primaryPictureList: [],
        bannerList: [],
        backgroundColor: "",
        payType: "",
        time: [],
        state: 1,
        sort: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        primaryPictureList: [
          { required: true, message: "请上传活动列表图", trigger: "blur" },
        ],
        bannerList: [
          { required: true, message: "请上传活动轮播图", trigger: "blur" },
        ],
        backgroundColor: [
          { required: true, message: "请设置活动背景色", trigger: "blur" },
        ],
        payType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        time: [{ required: true, message: "请选择有效时间", trigger: "blur" }],
        state: [{ required: true, message: "请选择", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.primaryPictureList = [...e];
        this.$forceUpdate();
      } else {
        this.form.primaryPictureList = [...this.form.primaryPictureList, e];
        this.$forceUpdate();
      }
    },
    uploadBanner(e) {
      if (Array.isArray(e)) {
        this.form.bannerList = [...e];
        this.$forceUpdate();
      } else {
        this.form.bannerList = [...this.form.bannerList, e];
        this.$forceUpdate();
      }
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    //获取门店接口

    getList() {
      this.listLoading = true;
      activityList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },

    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            activityDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },

    onchangeIsShow(row) {
      activityStateUp({ id: row.id, state: row.state })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.title = "新增活动";
      this.dialogVisible = true;
    },
    //管理商品
    editGoods(id) {
      this.$router.push({
        path: "/market/activityGoods",
        query: {
          id: id,
        },
      });
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑活动";
      row.primaryPictureList = [row.primaryPicture];
      row.bannerList = row.banner.split(",");
      row.time = [row.startTime, row.endTime];
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.vLoading = true;
            activityUp({
              ...this.form,
              primaryPicture: this.form.primaryPictureList[0],
              banner: this.form.bannerList.toString(),
              startTime: this.form.time[0],
              endTime: this.form.time[1],
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                this.vLoading = false;
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.getList();
                  this.handleClose();
                  // done();
                } else {
                  this.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                this.vLoading = false;
              });
          } else {
            this.vLoading = true;
            activityAdd({
              ...this.form,
              primaryPicture: this.form.primaryPictureList[0],
              banner: this.form.bannerList.toString(),
              startTime: this.form.time[0],
              endTime: this.form.time[1],
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                this.vLoading = false;
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  this.getList();
                  this.handleClose();
                  // done();
                } else {
                  this.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                this.vLoading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.colorBox {
  width: 34px;
  height: 34px;
}
</style>
  